import React from 'react';
import { ChatContainer } from './ChatContainer';
import { AnimatePresence, motion } from 'framer-motion';

interface IWidgetChat {
  open: boolean;
  onClose: () => void;
}

export const WidgetChat: React.FC<IWidgetChat> = ({ open, onClose }) => {
  return (
    <div className="relative z-50">
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{
              type: 'spring',
              bounce: 0.05,
              duration: 0.25,
            }}
            className="fixed bottom-5 lg:bottom-0 right-[5%] lg:right-20 h-[530px] w-[90%] lg:w-[420px] bg-th-background shadow-lg rounded-xl lg:rounded-b-none lg:rounded-t-xl flex flex-col z-[50] origin-bottom-right overflow-hidden"
            initial={{ opacity: 0, scale: 0.8, y: 100 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.8, y: 100 }}
          >
            <ChatContainer onClose={onClose} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
